import React from "react"
import Page from "../../components/page"
import ResponsiveImageGrid from "../../components/responsive-image-grid"
import ScrollDown from "../../components/scroll-down"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"
import useScreenSize from "../../utils/useScreenSize"

const interiorsGrid = (labels, isSmallScreen, pagePaths) =>
  isSmallScreen
    ? [
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: [
            {
              type: "image",
              weight: 100,
              src: "/images/interiors/business-and-public-interiors.jpg",
              mode: isSmallScreen ? undefined : "full-screen",
              alt: labels.interiorsBusinessAndPublicAlt,
              imgTitle: labels.interiorsBusinessAndPublicImgTitle,
              text: labels.interiorsBusinessAndPublicText,
              textContainerClass:
                "interiors-business-and-public-text-container",
              textClass: "interiors-business-and-public-text",
              link: pagePaths.publicAndBusinessInteriors,
              showClickMe: true
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: [
            {
              type: "image",
              weight: 100,
              src: "/images/interiors/private-interiors-boudoir-collection.jpg",
              mode: isSmallScreen ? undefined : "full-screen",
              alt: labels.interiorsPrivateAlt,
              imgTitle: labels.interiorsPrivateImgTitle,
              text: labels.interiorsPrivateText,
              textContainerClass: "interiors-private-text-container",
              textClass: "interiors-private-text",
              link: pagePaths.privateInteriors,
              showClickMe: true
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: [
            {
              type: "image",
              weight: 100,
              src: isSmallScreen
                ? "/images/interiors/game-of-lights-vertical.jpg"
                : "/images/interiors/game-of-lights-horizontal.jpg",
              mode: isSmallScreen ? undefined : "full-screen",
              fit: isSmallScreen ? "cover" : "fill",
              alt: labels.interiorsGameOfLightsAlt,
              imgTitle: labels.interiorsGameOfLightsImgTitle,
              text: labels.interiorsGameOfLightsText,
              textContainerClass: "interiors-game-of-lights-text-container",
              textClass: "interiors-game-of-lights-text",
              link: pagePaths.gameOfLightsInteriors,
              showClickMe: true
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: [
            {
              type: "image",
              weight: 100,
              src: "/images/interiors/back-room-eye-keyhole.jpg",
              containerClass:
                "img-container interiors-back-room-eye-keyhole-img-container",
              alt: labels.interiorsBackRoomAlt,
              imgTitle: labels.interiorsBackRoomImgTitle,
              text: labels.interiorsBackRoomText,
              textContainerClass: "interiors-back-room-text-container",
              textClass: "interiors-back-room-text",
              link: pagePaths.backRoomInteriors,
              showClickMe: true
            }
          ]
        }
      ]
    : [
        {
          type: "grid",
          orientation: "horizontal",
          grid: [
            {
              type: "image",
              weight: 100,
              src: "/images/interiors/business-and-public-interiors.jpg",
              mode: isSmallScreen ? undefined : "full-screen",
              alt: labels.interiorsBusinessAndPublicAlt,
              imgTitle: labels.interiorsBusinessAndPublicImgTitle,
              text: labels.interiorsBusinessAndPublicText,
              textContainerClass:
                "interiors-business-and-public-text-container",
              textClass: "interiors-business-and-public-text"
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: [
            {
              type: "image",
              weight: 49.25,
              src: "/images/interiors/interior-z-office-lobby.jpg",
              alt: labels.interiorsInteriorZOfficesLobbyAlt,
              imgTitle: labels.interiorsInteriorZOfficesLobbyImgTitle,
              text: labels.interiorsInteriorZOfficesText,
              textContainerClass: "interiors-interior-z-offices-text-container",
              textClass: "interiors-interior-z-offices-text"
            },
            {
              type: "image",
              weight: 49.25,
              src: "/images/interiors/interior-z-office-hallway.jpg",
              alt: labels.interiorsInteriorZHallwayAlt,
              imgTitle: labels.interiorsInteriorZHallwayImgTitle
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          unified: true,
          grid: [
            {
              type: "image",
              weight: 49.25,
              src: "/images/interiors/interior-z-lighting-design-lobby.jpg",
              alt: labels.interiorsInteriorZLightingDesignLobbyAlt,
              imgTitle: labels.interiorsInteriorZLightingDesignLobbyImgTitle
            },
            {
              type: "image",
              weight: 49.25,
              src: "/images/interiors/interior-z-lighting-design-box.jpg",
              alt: labels.interiorsInteriorZLightingDesignBoxAlt,
              imgTitle: labels.interiorsInteriorZLightingDesignBoxImgTitle
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: [
            {
              type: "image",
              weight: isSmallScreen ? 49.25 : 100,
              src: isSmallScreen
                ? "/images/interiors/the-best-life-wall-tables.jpg"
                : "/images/interiors/the-best-life.jpg",
              containerClass:
                "img-container interiors-the-best-life-img-container",
              fit: isSmallScreen ? "contain" : "fill",
              alt: isSmallScreen
                ? labels.interiorsTheBestLifeBarAndNightClubWallTablesAlt
                : labels.interiorsTheBestLifeBarAndNightClubAlt,
              imgTitle: isSmallScreen
                ? labels.interiorsTheBestLifeBarAndNightClubWallTablesImgTitle
                : labels.interiorsTheBestLifeBarAndNightClubImgTitle,
              text: labels.interiorsTheBestLifeBarAndNightClubText,
              textContainerClass:
                "interiors-the-best-life-bar-and-night-club-text-container",
              textClass: "interiors-the-best-life-bar-and-night-club-text"
            },
            {
              type: "image",
              weight: isSmallScreen ? 49.25 : 0,
              src: "/images/interiors/the-best-life-lighting-dark.jpg",
              alt: labels.interiorsTheBestLifeBarAndNightClubDarkAlt,
              imgTitle: labels.interiorsTheBestLifeBarAndNightClubDarkImgTitle
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: [
            {
              type: "image",
              weight: 100,
              src: "/images/interiors/conceptual-solutions-drawings.jpg",
              containerClass:
                "img-container interiors-conceptual-solutions-img-container",
              fit: isSmallScreen ? "contain" : "fill",
              alt: labels.interiorsConceptualSolutionsAlt,
              imgTitle: labels.interiorsConceptualSolutionsImgTitle,
              text: labels.interiorsConceptualSolutionsText,
              textContainerClass:
                "interiors-conceptual-solutions-text-container",
              textClass: "interiors-conceptual-solutions-text"
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          unified: true,
          grid: [
            {
              type: "image",
              weight: 35,
              src: "/images/interiors/3x-club-lightshow.jpg",
              containerClass:
                "img-container interiors-3x-club-lightshow-img-container",
              alt: labels.interiors3XClubLightshowAlt,
              imgTitle: labels.interiors3XClubLightshowImgTitle,
              text: labels.interiors3XClubText,
              textContainerClass: "interiors-3x-club-text-container",
              textClass: "interiors-3x-club-text"
            },
            {
              type: "image",
              weight: 63.5,
              src: "/images/interiors/3x-club-gif.gif",
              containerClass:
                "img-container interiors-3x-club-gif-img-container",
              alt: labels.interiors3XClubGifAlt,
              imgTitle: labels.interiors3XClubGifImgTitle
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          unified: isSmallScreen,
          grid: [
            {
              type: "image",
              weight: 70,
              src: "/images/interiors/boutique-hotel-drawing-floor.jpg",
              containerClass:
                "img-container interiors-boutique-hotel-drawing-floor-img-container",
              alt: labels.interiorsBoutiqueHotelDrawingAlt,
              imgTitle: labels.interiorsBoutiqueHotelDrawingImgTitle,
              text: labels.interiorsBoutiqueHotelText,
              textContainerClass: "interiors-boutique-hotel-text-container",
              textClass: "interiors-boutique-hotel-text"
            },
            {
              type: "image",
              weight: 28.5,
              src: "/images/interiors/boutique-hotel-drawing-furniture.jpg",
              containerClass:
                "img-container interiors-boutique-hotel-drawing-furniture-img-container",
              alt: labels.interiorsRoom1DrawingAlt,
              imgTitle: labels.interiorsRoom1DrawingImgTitle,
              text: labels.interiorsRoom1Text,
              textContainerClass: "interiors-room-1-text-container",
              textClass: "interiors-room-1-text"
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          grid: [
            {
              type: "image",
              weight: 100,
              src: "/images/interiors/private-interiors-boudoir-collection.jpg",
              mode: isSmallScreen ? undefined : "full-screen",
              alt: labels.interiorsPrivateAlt,
              imgTitle: labels.interiorsPrivateImgTitle,
              text: labels.interiorsPrivateText,
              textContainerClass: "interiors-private-text-container",
              textClass: "interiors-private-text"
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: isSmallScreen
            ? [
                {
                  type: "image",
                  weight: 32.33,
                  src: "/images/interiors/apartment-b-cocktail-collection.jpg",
                  containerClass:
                    "img-container interiors-apartment-b-cocktail-collection-img-container",
                  alt: labels.interiorsApartmentBCocktailCollectionAlt,
                  imgTitle:
                    labels.interiorsApartmentBCocktailCollectionImgTitle,
                  text: labels.interiorsApartmentBText,
                  textContainerClass: "interiors-apartment-b-text-container",
                  textClass: "interiors-apartment-b-text"
                },
                {
                  type: "image",
                  weight: 32.33,
                  src: "/images/interiors/apartment-b.jpg",
                  containerClass:
                    "img-container interiors-apartment-b-img-container",
                  alt: labels.interiorsApartmentBAlt,
                  imgTitle: labels.interiorsApartmentBImgTitle
                },
                {
                  type: "image",
                  weight: 32.33,
                  src: "/images/interiors/apartment-b-mens-secrets.jpg",
                  alt: labels.interiorsApartmentBMensSecretsAlt,
                  imgTitle: labels.interiorsApartmentBMensSecretsImgTitle
                }
              ]
            : [
                {
                  type: "image",
                  weight: 32.33,
                  src: "/images/interiors/apartment-b.jpg",
                  containerClass:
                    "img-container interiors-apartment-b-img-container",
                  alt: labels.interiorsApartmentBAlt,
                  imgTitle: labels.interiorsApartmentBImgTitle
                },
                {
                  type: "grid",
                  weight: 32.34,
                  orientation: "vertical",
                  grid: [
                    {
                      type: "html",
                      weight: 48.5,
                      html: (
                        <div className="paragraph-div-centered-relatively interiors-apartment-b-text-container">
                          <p>{labels.interiorsApartmentBText}</p>
                        </div>
                      )
                    },
                    {
                      type: "image",
                      weight: 48.5,
                      src: "/images/interiors/apartment-b-mens-secrets.jpg",
                      alt: labels.interiorsApartmentBMensSecretsAlt,
                      imgTitle: labels.interiorsApartmentBMensSecretsImgTitle
                    }
                  ]
                },
                {
                  type: "image",
                  weight: 32.33,
                  src: "/images/interiors/apartment-b-cocktail-collection.jpg",
                  containerClass:
                    "img-container interiors-apartment-b-cocktail-collection-img-container",
                  alt: labels.interiorsApartmentBCocktailCollectionAlt,
                  imgTitle: labels.interiorsApartmentBCocktailCollectionImgTitle
                }
              ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: [
            {
              type: "image",
              weight: 49.25,
              src: "/images/interiors/apartment-p.jpg",
              containerClass:
                "img-container interiors-apartment-p-img-container",
              alt: labels.interiorsApartmentPAlt,
              imgTitle: labels.interiorsApartmentPImgTitle,
              text: labels.interiorsApartmentPText,
              textContainerClass: "interiors-apartment-p-text-container",
              textClass: "interiors-apartment-p-text"
            },
            {
              type: "image",
              weight: 49.25,
              src: "/images/interiors/x-armchair-red-mask.jpg",
              containerClass:
                "img-container interiors-x-armchair-red-mask-img-container",
              alt: labels.interiorsApartmentPXArmchairRedAlt,
              imgTitle: labels.interiorsApartmentPXArmchairRedImgTitle
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: !isSmallScreen,
          unified: true,
          gridClass: "interiors-apartment-k-grid-container",
          grid: [
            {
              type: "image",
              weight: isSmallScreen ? 47 : 32.33,
              src: "/images/interiors/apartment-k-living-room.jpg",
              containerClass:
                "img-container interiors-apartment-k-living-room-img-container",
              alt: labels.interiorsApartmentKLivingRoomAlt,
              imgTitle: labels.interiorsApartmentKLivingRoomImgTitle,
              text: labels.interiorsApartmentKText,
              textContainerClass: "interiors-apartment-k-text-container",
              textClass: "interiors-apartment-k-text"
            },
            {
              type: "image",
              weight: isSmallScreen ? 47 : 32.33,
              src: "/images/interiors/apartment-k-hallway.jpg",
              containerClass:
                "img-container interiors-apartment-k-hallway-img-container",
              alt: labels.interiorsApartmentKHallwayAlt,
              imgTitle: labels.interiorsApartmentKHallwayImgTitle
            },
            {
              type: "image",
              weight: isSmallScreen ? 0 : 32.33,
              src: "/images/interiors/apartment-k-bookshelf.jpg",
              containerClass:
                "img-container interiors-apartment-k-bookshelf-img-container",
              alt: labels.interiorsApartmentKBookshelfAlt,
              imgTitle: labels.interiorsApartmentKBookshelfImgTitle
            }
          ]
        },
        {
          type: isSmallScreen ? "grid" : undefined,
          orientation: "horizontal",
          margin: isSmallScreen,
          grid: [
            {
              type: "image",
              weight: isSmallScreen ? 100 : 0,
              src: "/images/interiors/apartment-k-bookshelf.jpg",
              containerClass:
                "img-container interiors-apartment-k-bookshelf-img-container",
              alt: labels.interiorsApartmentKBookshelfAlt,
              imgTitle: labels.interiorsApartmentKBookshelfImgTitle
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          grid: [
            {
              type: "image",
              weight: 100,
              src: isSmallScreen
                ? "/images/interiors/game-of-lights-vertical.jpg"
                : "/images/interiors/game-of-lights-horizontal.jpg",
              mode: isSmallScreen ? undefined : "full-screen",
              fit: isSmallScreen ? "cover" : "fill",
              alt: labels.interiorsGameOfLightsAlt,
              imgTitle: labels.interiorsGameOfLightsImgTitle,
              text: labels.interiorsGameOfLightsText,
              textContainerClass: "interiors-game-of-lights-text-container",
              textClass: "interiors-game-of-lights-text"
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: !isSmallScreen,
          grid: [
            {
              type: "image",
              weight: 49.25,
              src: "/images/interiors/xxx-commode-david-bovie-quilling.jpg",
              containerClass:
                "img-container interiors-xxx-commode-david-bovie-quilling-img-container",
              alt: labels.interiorsXxxCommodeDavidBowieQuillingAlt,
              imgTitle: labels.interiorsXxxCommodeDavidBowieQuillingImgTitle
            },
            {
              type: "image",
              weight: 49.25,
              src:
                "/images/interiors/wake-up-for-make-up-collection-purple.jpg",
              containerClass:
                "img-container interiors-wake-up-for-make-up-collection-purple-img-container",
              position: isSmallScreen ? undefined : "center 70%",
              alt: labels.interiorsWakeUpForMakeUpPurpleAlt,
              imgTitle: labels.interiorsWakeUpForMakeUpPurpleImgTitle
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: !isSmallScreen,
          grid: [
            {
              type: "image",
              weight: 100,
              src: "/images/interiors/back-room-eye-keyhole.jpg",
              containerClass:
                "img-container interiors-back-room-eye-keyhole-img-container",
              alt: labels.interiorsBackRoomAlt,
              imgTitle: labels.interiorsBackRoomImgTitle,
              text: labels.interiorsBackRoomText,
              textContainerClass: "interiors-back-room-text-container",
              textClass: "interiors-back-room-text"
            }
          ]
        },
        {
          type: "grid",
          orientation: "horizontal",
          margin: true,
          grid: isSmallScreen
            ? [
                {
                  type: "image",
                  weight: 57.5,
                  src: "/images/interiors/deep-throat-eye.jpg",
                  containerClass:
                    "img-container interiors-deep-throat-eye-img-container",
                  alt: labels.interiorsBackRoomDeepThroatEyeAlt,
                  imgTitle: labels.interiorsBackRoomDeepThroatEyeImgTitle
                },
                {
                  type: "image",
                  weight: 41,
                  src: "/images/interiors/deep-throat-side.jpg",
                  containerClass:
                    "img-container interiors-deep-throat-side-img-container",
                  alt: labels.interiorsBackRoomDeepThroatSideAlt,
                  imgTitle: labels.interiorsBackRoomDeepThroatSideImgTitle
                }
              ]
            : [
                {
                  type: "image",
                  weight: 41,
                  src: "/images/interiors/deep-throat-side.jpg",
                  containerClass:
                    "img-container interiors-deep-throat-side-img-container",
                  alt: labels.interiorsBackRoomDeepThroatSideAlt,
                  imgTitle: labels.interiorsBackRoomDeepThroatSideImgTitle
                },
                {
                  type: "image",
                  weight: 57.5,
                  src: "/images/interiors/deep-throat-eye.jpg",
                  containerClass:
                    "img-container interiors-deep-throat-eye-img-container",
                  alt: labels.interiorsBackRoomDeepThroatEyeAlt,
                  imgTitle: labels.interiorsBackRoomDeepThroatEyeImgTitle
                }
              ]
        }
      ]

export default function Interiors({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.interiors}
      description={labels.interiorsDescription}
      keywords={labels.interiorsKeywords}
      ogUrl={paths(labels).interiors}
      ogImage="/images/og-images/interiors.jpg"
      headerPadding={isSmallScreen}
      footerPadding={true}
    >
      <ScrollDown
        bigScreenOnly={true}
        // arrowClassName="scroll-down-arrow-black"
      />
      <ResponsiveImageGrid
        gridClass="interiors-grid-container"
        grid={interiorsGrid(labels, isSmallScreen, paths(labels))}
        margin={false}
      />
    </Page>
  )
}
